import React from 'react';
import './CV.css';

function ExhibitionsAndNews() {
  return (
    <div className="cv-container">
      <h1>Exhibitions & News</h1>
      
      <section>
        <h2>Upcoming Exhibitions</h2>
        <ul>
          <li>
            <strong>Minneapolis Sculpture Garden Art Fair 2025</strong><br />
            Minneapolis, Minnesota<br />
            <time dateTime="2025-05-10">May 10 - 11, 2025</time><br />
          </li>
          <li>
            <strong>MSU 61st Annual Spring Arts & Crafts Show 2025</strong><br />
            East Lansing, Michigan<br />
            <time dateTime="2025-05-17">May 17 - 18, 2025</time><br />
          </li>
          <li>
            <strong>Traverse City Spring Art & Craft Show</strong><br />
            Traverse City, Michigan<br />
            <time dateTime="2025-05-31">May 31 - June 1, 2025</time><br />
          </li>
          <li>
            <strong>Hinsdale Fine Arts Festival 2025</strong><br />
            Hinsdale, Illinois<br />
            <time dateTime="2025-06-07">June 7 - 8, 2025</time><br />
          </li>
          <li>
            <strong>South Haven Art Fair 2025</strong><br />
            South Haven, Michigan<br />
            <time dateTime="2025-07-05">July 5 - 6, 2025</time><br />
          </li>
          <li>
            <strong>Grand Traverse Bay Summer Art & Craft Show</strong><br />
            Traverse City, Michigan<br />
            <time dateTime="2025-08-16">August 16 - 17, 2025</time><br />
          </li>
          <li>
            <strong>Art in the Barn 2025</strong><br />
            Barrington, Illinois<br />
            <time dateTime="2025-09-27">September 27 - 28, 2025</time><br />
          </li>
        </ul>
      </section>
    </div>
  );
}

export default ExhibitionsAndNews;